import React, { FunctionComponent, PropsWithChildren } from 'react'
import { getTheme } from "@fluentui/react";

const theme = getTheme();

export interface ICardProps {

}

export function Card({children}:PropsWithChildren<ICardProps>) {
    return (<div style={{ margin: "auto", width: "min(90%, 1200px)", boxShadow: theme.effects.elevation16, backgroundColor: theme.semanticColors.bodyBackground }}>
                        <div style={{ margin: "10px", padding: "10px" }}>
            {children}
        </div>
    </div>);
}