import { Configuration, PopupRequest } from "@azure/msal-browser";

const resource_id : string = process.env.REACT_APP_API_RESOURCE_ID ?? "";
const adb2c_client_id : string = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";
const adb2c_authority : string = process.env.REACT_APP_ADB2C_AUTHORITY ?? "";


// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: adb2c_client_id,
        authority: adb2c_authority,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["openid", "offline_access", resource_id]
};