export interface UserInfo {
    identityName: string,
    authenticationType: string
    claims: ClaimInfo[]
}

export interface ClaimInfo {
    name: string,
    value: string,
    type: string
}

const baseUrl : string = process.env.REACT_APP_API_BASE_URL ?? "";

export async function getUserInfo(accessToken: string) {
    const url = `${baseUrl}/user`;

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit = {
        method: "GET",
        redirect: 'error',
        headers: headers
    };

    const response = await fetch(url, options);
    const data = (await response.json()) as UserInfo;

    return data;
}