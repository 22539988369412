import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { CheckboxVisibility, DetailsList, getTheme, IColumn, IStackStyles, IStackTokens, Label, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Card } from '../components/general/card';
import { getUserInfo, UserInfo } from '../services/tradingApi';
const resource_id: string = process.env.REACT_APP_API_RESOURCE_ID ?? "";


async function getAuthToken(instance: IPublicClientApplication) {
    const account = instance.getAllAccounts()[0]
    try {
        return await instance.acquireTokenSilent({ account, scopes: ['openid', 'offline_access', resource_id] })
    } catch (e) {
        return await instance.acquireTokenPopup({ account, scopes: ['openid', 'offline_access', resource_id] });
    }
}

const _columns: IColumn[] = [{
    key: 'name',
    name: 'Claim',
    fieldName: 'name',
    minWidth: 250,
    isSorted: true,
    data: 'string',
    isPadded: true,
    isResizable: true
},
{
    key: 'value',
    name: 'Value',
    fieldName: 'value',
    minWidth: 250,
    data: 'string',
    isPadded: true,
},
{
    key: 'valueType',
    name: 'Type',
    fieldName: 'type',
    minWidth: 250,
    data: 'string',
    isPadded: true,
},
];

const pageStackTokens: IStackTokens = { childrenGap: 10 };
const propertiesStackTokens: IStackTokens = { childrenGap: 30 };
const theme = getTheme();

export function UserInfoPage() {
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const { instance } = useMsal();

    useEffect(() => {
        (async () => {
            const token = await getAuthToken(instance);
            const result = await getUserInfo(token.accessToken);
            setUserInfo(result);
        })();
    }, []);

    if (!userInfo) return <Spinner size={SpinnerSize.large} />;

    return (
        <>
            <Stack tokens={pageStackTokens} horizontalAlign='stretch'>
                <Stack.Item>
                    <Card>
                        <Stack horizontal horizontalAlign="start" tokens={propertiesStackTokens} >
                            <Stack.Item>
                                <div style={{ textAlign: 'start' }}>
                                    <Label>Name</Label> <Text>{userInfo?.identityName ?? 'Unknown'}</Text>
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <div style={{ textAlign: 'start' }}>
                                    <Label>Authentication method</Label> <Text>{userInfo?.authenticationType ?? 'Unknown'}</Text>
                                </div>
                            </Stack.Item>
                        </Stack>
                    </Card>
                </Stack.Item>
                <Stack.Item>
                    <Card>
                        <DetailsList
                            items={userInfo?.claims as any[]}
                            columns={_columns}
                            checkboxVisibility={CheckboxVisibility.hidden}
                        >

                        </DetailsList>
                    </Card>
                </Stack.Item>
            </Stack>
        </>
    );
}