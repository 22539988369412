import React from 'react';
import logo from './logo.svg';
import './App.css';
import AccountList from './components/accountList'
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { NavHeader } from './components/navHeader';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { UserInfoPage } from './pages/userInfo';
import { getTheme, Stack } from '@fluentui/react';

initializeIcons();

const theme = getTheme();

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  console.log('colors', theme);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Popup} authenticationRequest={loginRequest}>
        <Router>
          <div className="App" style={{ backgroundColor: theme.semanticColors.bodyStandoutBackground }}>
            <Stack>
              <Stack.Item>
                <NavHeader />
              </Stack.Item>
              <Stack.Item>
                <Switch>
                  <Route path="/user"><UserInfoPage /></Route>
                  <Route exact path="/">Home</Route>
                </Switch>
              </Stack.Item>
            </Stack>
          </div>
        </Router>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
