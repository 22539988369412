import React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { ContextualMenuItem, ContextualMenuItemType, IContextualMenuItemProps } from '@fluentui/react/lib/ContextualMenu';
import { Link } from 'react-router-dom';
import { CommandBarButton, DefaultEffects } from '@fluentui/react';


export const NavHeader: React.FunctionComponent = () => {
    const _farItems = [AccountButton(), SignOutButton()]
    
    return (
      <div style={{ boxShadow: DefaultEffects.elevation16 }}>
        <CommandBar
          items={_items}
          farItems={_farItems}
          ariaLabel="Use left and right arrow keys to navigate between commands"
        />
      </div>
    );
  };
  
  const _items: ICommandBarItemProps[] = [
    {
      key: 'accounts',
      text: 'Accounts',
      iconProps: { iconName: 'Financial' },
      commandBarButtonAs: CommandbarLink,
      href: '/accounts',
      subMenuProps: {
        items: [
          {
            key: 'emailMessage',
            text: 'Email message',
            iconProps: { iconName: 'Mail' },
            ['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: 'calendarEvent',
            text: 'Calendar event',
            iconProps: { iconName: 'Calendar' },
          },
        ],
      },
    },
  ];
  
const dateTimeToString = (dateTime:Date) => dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();

interface IdTokenClaims {
    auth_time:number,
    emails:string[],
    idp:string
}

function CommandbarLink(props: ICommandBarItemProps) {
  const WrappedCommandbarButton = () => <CommandBarButton {...(props as any)} />;

  return <Link component={WrappedCommandbarButton} to={props.href!} />
}
  
function AccountButton(): ICommandBarItemProps
{
    const { accounts } = useMsal();
    const account = accounts[0] as AccountInfo;
    const name = account?.name;    
    console.log('account', account);
    const idClaims = account.idTokenClaims as IdTokenClaims;

    return {
      key: 'loginName',
      text: 'Hallo ' + name,      
      title: `Logged in at ${dateTimeToString(new Date(idClaims?.auth_time * 1000))} as ${idClaims?.emails[0]} via ${idClaims.idp}`,
      commandBarButtonAs: CommandbarLink,
      href: '/user/'
    }
}

function SignOutButton() : ICommandBarItemProps 
{
    const { instance } = useMsal();
    return    {
      key: 'signOut',
      text: 'Sign out',
      iconOnly: true,
      iconProps: { iconName: 'SignOut' },
      ['data-automation-id']: 'signOut', // optional
      onClick: (e) => { instance.logout(); }
    };
}
  